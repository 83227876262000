// import { FaFacebookrSquare } from 'react-icons/fa';
import { AiFillInstagram, AiOutlineMail } from 'react-icons/ai';
import {
  SiItunes, SiTidal, SiApplemusic,
  SiSpotify, SiInstagram, SiTiktok,
  SiYoutube, SiBandcamp, SiTwitter,
  SiFacebook
} from 'react-icons/si';

const content = {
  tagline: 'Welcome to',
  title: 'Chaizer Music',
  subtitle: 'Enjoy the Vibes',
  links: [
    {
      to: 'https://open.spotify.com/artist/6ezrdvVQLm8LMW6rCVu0Fv?si=yOh8LGSOT3WLccMFXN7Svg',
      text: '',
      Icon: SiSpotify,
      newTab: true,
    },
    {
      to: 'https://music.apple.com/us/artist/chaizer/1438560797',
      text: '',
      Icon: SiApplemusic,
      newTab: true,
    },
    {
      to: 'https://tidal.com/browse/artist/10439223',
      text: '',
      Icon: SiTidal,
      newTab: true,
    },
    {
      to: 'https://facebook.com/chaizer.music',
      text: '',
      Icon: SiFacebook,
      newTab: true,
    },
    {
      to: 'https://instagram.com/chaizermusic',
      text: '',
      Icon: SiInstagram,
      newTab: true,
    },
    {
      to: 'https://twitter.com/ChaizerMusic',
      text: '',
      Icon: SiTwitter,
      newTab: true,
    },
    {
      to: 'https://www.youtube.com/channel/UCOL_bVrhjzhMq5eijejJAHw',
      text: '',
      Icon: SiYoutube,
      newTab: true,
    },
    {
      to: 'https://www.tiktok.com/@chaizer.music',
      text: '',
      Icon: SiTiktok,
      newTab: true,
    },
    {
      to: 'https://chaizer.bandcamp.com/music',
      text: '',
      Icon: SiBandcamp,
      newTab: true,
    },
    // {
    //   to: 'https://www.youtube.com/channel/UCOL_bVrhjzhMq5eijejJAHw',
    //   text: '',
    //   Icon: AiOutlineMail,
    //   newTab: true,
    // },

  ],
};

export default content;
